<template>

  <div class="auth-wrapper auth-v1 px-2">

    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link
          class="brand-logo"
          :to="{name:'accueil'}"
        >
          <b-img
            :src="appLogoImage"
            width="40"
            height="40"
            alt="logo"
          />

          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>

        </b-link>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="form.email"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="email@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Mot de passe</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>Mot de passe oublié ?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="mot de passe"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Mot de passe"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="form.remember_me"
                name="checkbox-1"
              >
                Se souvenir de moi
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              size="sm"
              block
              :disabled="invalid || process"
            >
              Se connecter
              <b-spinner
                v-if="process"
                small
                label="Loading..."
                variant="light"
                class="ml-1"
              />
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Etes-vous nouveau ? </span>
          <b-link :to="{name:'auth-register'}">
            <span>&nbsp; Créer votre compte</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text" />
        </div>

        <!-- social button -->
        <div class="auth-footer-btn d-flex justify-content-center">
          Copyright Quality Corporate All Right reserved
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>
<script>

import {
  reactive,
} from '@vue/composition-api'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BSpinner, BImg, BFormInput, BFormGroup, BCard, BLink, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// eslint-disable-next-line import/no-cycle
import useAuthenticate from '@/services/authentification/authService'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BImg,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
    }
  },
  setup() {
    const {
      handleLogin, process,
    } = useAuthenticate()
    const { appName, appLogoImage } = $themeConfig.app

    const form = reactive({
      email: '',
      password: '',
      remember_me: false,
    })

    const login = async () => {
      await handleLogin(form)
    }
    return {
      form,
      login,
      process,
      appName,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-wrapper {
 background-image: url(../../assets/images/banner1.jpg);
 background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
//  background-repeat: no-repeat;
}
</style>
